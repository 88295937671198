import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { linkResolver } from "../../../../utils/linkResolver";
import get from "lodash.get";
import { RichText } from "prismic-reactjs";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

import Carousel from "../../../../components/Carousel";
import ImageList from "../../../../components/ImageList";
import PrimaryButton from "../../../../components/PrimaryButton";

import Container from "../../../../styles/Container";
import HelmetInfo from "../../../../components/HelmetInfo";

const ImageContainer = styled.div`
  position: relative;
  padding-top: 23px;
  margin-bottom: 24px;
  max-width: 1164px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 33px;
    margin-bottom: 44px;
  }
`;

const Image = styled.img`
  width: 100%;
  min-height: 205px;
  object-fit: cover;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 450px;
  }
  @media (min-width: 1400px) {
    height: 600px;
  }
`;

const ImageText = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: initial;
  }
`;

const ImageHeading = styled.h1`
  font-size: 25px;
  line-height: 30px;
  font-weight: 900;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 0px;
  }
`;

const DescriptionContainer = styled(Container)`
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    h1,
    p {
      text-align: left;
    }
  }
`;

const MoviePoster = styled.img`
  width: 100%;
  max-width: 290px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    min-width: 290px;
    margin-bottom: 0px;
    margin-left: initial;
    margin-right: initial;
    img {
      object-fit: contain !important;
    }
  }
`;

const Heading = styled.h1`
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 26px;
    line-height: 28px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const SmallHeading = styled.h1`
  font-size: 20px;
  line-height: 30px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 26px;
    line-height: 28px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const Description = styled.div`
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
  h1 {
    margin-bottom: 34px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 900;
    text-align: center;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-bottom: 40px;
      font-size: 30px;
      line-height: 43px;
    }
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 26px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
`;

const ButtonWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: 17px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 50px;
    padding-right: 50px;
    > div {
      justify-content: flex-start;
    }
  }
`;

const MovieInformation = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  background-color: ${(p) => p.theme.lightBlue};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const MovieInformationInnerContainer = styled(Container)`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

const IFrameOuterContainer = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 550px;
    width: 100%;
    margin-left: 43px;
    margin-right: 56px;
    margin-bottom: 0px;
  }
`;

const IFrameContainer = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const MovieInformationText = styled.div`
  text-align: center;
  line-height: 26px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    min-width: 430px;
    text-align: left;
    font-size: 20px;
    line-height: 42px;
  }
`;

const QuoteContainer = styled.div`
  padding-top: 17px;
  padding-bottom: 35px;
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 0px;
  }
`;

const Quote = styled.blockquote`
  margin: 0px;
  margin-top: 15px;
  font-style: italic;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  strong {
    font-weight: 900;
  }
`;

const FestivalContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 35px;
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 0px;
  }
`;

const Festivals = styled.div`
  margin-top: 16px;
`;

const Festival = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  :not(:last-child) {
    margin-bottom: 16px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    :not(:last-child) {
      margin-bottom: 28px;
    }
  }
`;

const QuoteAndFestivalContainer = styled(Container)`
  .control-dots {
    position: relative !important;
    bottom: -16px !important;
  }
  .slide {
    background: white !important;
  }
  .dot {
    opacity: 1 !important;
    width: 11px !important;
    height: 11px !important;
    background-color: ${(p) => p.theme.lightMidGrey} !important;
    box-shadow: none !important;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      width: 12px !important;
      height: 12px !important;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    :hover {
      background-color: ${(p) => p.theme.midGrey} !important;
    }
  }
  .dot.selected {
    background-color: ${(p) => p.theme.midGrey} !important;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    margin-top: 65px;
    div {
      width: 100%;
    }
  }
`;

const MobileTitleContainer = styled.div`
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`;

const MobileTitle = styled.h1`
  font-size: 28px;
  line-height: 34px;
  font-weight: 900;
  text-align: center;
`;

const Movie = ({ data, prismic }) => {
  const title = get(data, "prismicMovie.data.title");
  const genre = get(data, "prismicMovie.data.genre");
  const image = get(data, "prismicMovie.data.hero_image.url");
  const imageAlt = get(data, "prismicMovie.data.hero_image.alt");
  const moviePoster = get(data, "prismicMovie.data.movie_poster.url");
  const moviePosterAlt = get(data, "prismicMovie.data.movie_poster.alt");
  const description = get(data, "prismicMovie.data.description.richText");
  const buttons = get(data, "prismicMovie.data.buttons");
  const movieInformation = get(data, "prismicMovie.data.movie_information");
  const YoutubeVideoId = get(data, "prismicMovie.data.youtube_video_id");
  const pressQuotes = get(data, "prismicMovie.data.press_quotes");
  const hasPressQuotes = get(pressQuotes, "[0].quote");
  const festivals = get(data, "prismicMovie.data.festivals");

  let festivalPairs = undefined;
  if (Array.isArray(festivals)) {
    festivalPairs = festivals.reduce(function (result, value, index, array) {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);
  }
  const hasFestivals = get(festivals, "[0].festival_name");

  const gallery = get(data, "prismicMovie.data.gallery");
  let galleryItems = undefined;
  if (Array.isArray(gallery)) {
    galleryItems = gallery.map((item) => {
      return {
        image: get(item, "gallery_image"),
      };
    });
  }
  const hasGallery = get(galleryItems, "[0].image");
  const relatedTitles = get(data, "allPrismicMovie.edges");

  let relatedTitlesItems = undefined;
  if (Array.isArray(relatedTitles)) {
    relatedTitlesItems = relatedTitles
      .filter((item) => {
        if (get(item, "node.data.genre") === genre) {
          if (get(item, "node.data.title") !== title) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
      .map((item) => {
        return {
          title1: get(item, "node.data.title"),
          image: get(item, "node.data.movie_poster"),
          link: {
            url: linkResolver(get(item, "node")),
          },
          noBlank: true,
        };
      });
  }
  const hasRelatedTitles = get(relatedTitlesItems, "[0].title1");

  return (
    <>
      <HelmetInfo page_data={data.prismicMovie.data} />
      <ImageContainer>
        <Image src={image} alt={imageAlt} />
        <ImageText>{title && <ImageHeading>{title}</ImageHeading>}</ImageText>
      </ImageContainer>
      <DescriptionContainer>
        <MobileTitleContainer>
          {title && <MobileTitle>{title}</MobileTitle>}
        </MobileTitleContainer>
        <MoviePoster src={moviePoster} alt={moviePosterAlt} />
        {description && (
          <div>
            <Heading>About</Heading>
            <Description>
              <RichText render={description} />
            </Description>
            {buttons && get(buttons, "[0].button_text") && (
              <ButtonWrapper>
                {buttons.map((button, index) => {
                  const text = get(button, "button_text");
                  const link = get(button, "button_link.url");
                  if (text && link) {
                    return (
                      <PrimaryButton
                        externalLink={link}
                        text={text}
                        key={index}
                      />
                    );
                  }
                })}
              </ButtonWrapper>
            )}
          </div>
        )}
      </DescriptionContainer>
      {(YoutubeVideoId || movieInformation) && (
        <MovieInformation>
          <MovieInformationInnerContainer>
            {YoutubeVideoId && (
              <IFrameOuterContainer>
                <IFrameContainer>
                  <iframe
                    title="trailer"
                    src={`https://www.youtube-nocookie.com/embed/${YoutubeVideoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </IFrameContainer>
              </IFrameOuterContainer>
            )}
            {movieInformation && (
              <MovieInformationText>
                {RichText.render(movieInformation.richText)}
              </MovieInformationText>
            )}
          </MovieInformationInnerContainer>
        </MovieInformation>
      )}
      {(hasPressQuotes || hasFestivals) && (
        <QuoteAndFestivalContainer>
          {hasPressQuotes && (
            <QuoteContainer>
              <SmallHeading>Press</SmallHeading>
              {pressQuotes && pressQuotes.length > 1 ? (
                <ReactCarousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={5000}
                  transitionTime={500}
                  swipeScrollTolerance={50}
                >
                  {pressQuotes.map((pressQuote, index) => {
                    const quote = get(pressQuote, "quote");
                    const source = get(pressQuote, "source");
                    return (
                      <div key={index}>
                        <Quote>
                          {quote}
                          {source && (
                            <>
                              <br />
                              <strong>{source}</strong>
                            </>
                          )}
                        </Quote>
                      </div>
                    );
                  })}
                </ReactCarousel>
              ) : (
                pressQuotes.map((pressQuote, index) => {
                  const quote = get(pressQuote, "quote");
                  const source = get(pressQuote, "source");
                  return (
                    <Quote key={index}>
                      {quote}
                      {source && (
                        <>
                          <br />
                          <strong>{source}</strong>
                        </>
                      )}
                    </Quote>
                  );
                })
              )}
            </QuoteContainer>
          )}
          {hasFestivals && (
            <FestivalContainer>
              <SmallHeading>Festivals</SmallHeading>
              <Festivals>
                {festivalPairs && festivalPairs.length > 1 ? (
                  <ReactCarousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={5000}
                    transitionTime={500}
                    swipeScrollTolerance={50}
                  >
                    {festivalPairs.map((festival, index) => {
                      const festivalName1 = get(festival, "[0].festival_name");
                      const festivalName2 = get(festival, "[1].festival_name");
                      const festivalDate1 = get(festival, "[0].festival_date");
                      const festivalDate2 = get(festival, "[1].festival_date");
                      return (
                        <div key={index}>
                          <Festival>
                            {festivalName1 && (
                              <div>
                                <strong>{festivalName1}</strong>
                              </div>
                            )}
                            {festivalDate1 && <div>{festivalDate1}</div>}
                          </Festival>
                          <Festival>
                            {festivalName2 && (
                              <div>
                                <strong>{festivalName2}</strong>
                              </div>
                            )}
                            {festivalDate2 && <div>{festivalDate2}</div>}
                          </Festival>
                        </div>
                      );
                    })}
                  </ReactCarousel>
                ) : (
                  festivalPairs.map((festival, index) => {
                    const festivalName1 = get(festival, "[0].festival_name");
                    const festivalName2 = get(festival, "[1].festival_name");
                    const festivalDate1 = get(festival, "[0].festival_date");
                    const festivalDate2 = get(festival, "[1].festival_date");
                    return (
                      <div key={index}>
                        <Festival>
                          {festivalName1 && (
                            <div>
                              <strong>{festivalName1}</strong>
                            </div>
                          )}
                          {festivalDate1 && <div>{festivalDate1}</div>}
                        </Festival>
                        <Festival>
                          {festivalName2 && (
                            <div>
                              <strong>{festivalName2}</strong>
                            </div>
                          )}
                          {festivalDate2 && <div>{festivalDate2}</div>}
                        </Festival>
                      </div>
                    );
                  })
                )}
              </Festivals>
            </FestivalContainer>
          )}
        </QuoteAndFestivalContainer>
      )}
      {hasGallery && (
        <Carousel
          heading="GALLERY"
          items={galleryItems}
          noTopBorder
          noPadding
          squareOnMobile
        />
      )}
      {hasRelatedTitles && (
        <ImageList
          heading="Related Titles"
          largeHeading
          largeTitles
          items={relatedTitlesItems}
        />
      )}
    </>
  );
};

export default Movie;

export const query = graphql`
  query ($uid: String!) {
    allPrismicMovie(sort: { fields: first_publication_date, order: DESC }) {
      totalCount
      edges {
        node {
          ... on PrismicMovie {
            type
            uid
            data {
              movie_poster {
                url
                alt
              }
              title
              info
              genre
            }
          }
        }
      }
    }
    prismicMovie(uid: { eq: $uid }) {
      data {
        hero_image {
          url
          alt
        }
        title
        genre
        festivals {
          festival_name
        }
        movie_poster {
          url
          alt
        }
        description {
          text
          html
          richText
        }
        buttons {
          button_text
          button_link {
            url
            link_type
            uid
          }
        }
        youtube_video_id
        movie_information {
          text
          html
          richText
        }
        press_quotes {
          quote
          source
        }
        festivals {
          festival_name
          festival_date
        }
        gallery {
          gallery_image {
            url
            alt
          }
        }
      }
    }
  }
`;
